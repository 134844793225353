import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Spinner,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import "../SignUpForm/signupform.css";
import { CiUser } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone, BsChatLeftText } from "react-icons/bs";
import htmlParser from 'html-react-parser';


function trim(theStr) {
  return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
  var retVal = "";
  var okChars = "0123456789";
  var tmpVal = trim(inVal);
  var curChar = "";
  var isDotFound = false;

  if (tmpVal !== "") {
    for (var i = 0; i < tmpVal.length; i++) {
      curChar = tmpVal.charAt(i);

      if (okChars.indexOf(curChar) !== -1) {
        if (!isDotFound && curChar === "." && isDouble) {
          isDotFound = true;
          retVal = retVal + ".";
        }

        if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
          //Do nothing
        } else {
          retVal += curChar;
        }
      }
    }
  }

  return retVal;
}

function serialize(form) {
  let inputs = form.elements;
  let testArray = {};
  for (let i = 0; i < inputs.length - 1; i++) {
    testArray[inputs[i].id] = inputs[i].value;
  }
  return testArray;
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function getFormURL() {
  if (
    window.location.href.split("/")[3] &&
    window.location.href.split("/")[4]
  ) {
    const temp = window.location.href.split("/")[4].replace("#", "");
    return "/" + window.location.href.split("/")[3] + "/" + temp;
  } else {
    return "/";
  }
}

export default function SignUpForm({ links }) {
  let empDetails = "";
  const [referUrl, setReferUrl] = useState();

  const [validated, setValidated] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [leadNo, setLeadNo] = useState("");

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [consentValue, setConsentValue] = useState(true);
  const [showContactForm, setShowContactForm] = useState(true);
  const [showConsenSection, setShowConsenSection] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [showClickToMoreOpt, setShowClickToMoreOpt] = useState(true);
  const [state, setState] = useState("");
  const [referrerSource, setReferrerSource] = useState("");
  const [referrerName, setReferrerName] = useState("");
  const [statesOptions, setStatesOptions] = useState("");
  const [comment, setComment] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get("key");
  const utmSource = queryParams.get("utm_source") || undefined;
  const utmMedium = queryParams.get("utm_medium") || undefined;
  const utmCampaign = queryParams.get("utm_campaign") || undefined;
  const utmContent = queryParams.get("utm_content") || undefined;

  const referrerOptions = [
    "Sun West Email",
    "Sun West Newsletter",
    "Facebook",
    "Instagram",
    "Twitter",
    "Colleague",
    "Other social media",
    "Search Engine",
    "Other",
  ];

  function getParams(url) {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      pair[0] === "" && (pair[0] = "refer");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    setReferUrl(params);
    return params;
  }

  function decodeDetails(empDetails) {
    if (
      empDetails &&
      empDetails.refer !== undefined &&
      empDetails.refer !== "undefined"
    ) {
      var refererName = atob(empDetails.refer);
      setReferrerSource((prev) => {
        return {
          ...prev,
          referrerSource: "Other",
          referrerName: refererName,
        };
      });
    }
  }

  const handleFailClose = () => {
    setShowFailModal(false);
    setValidated(false);
  };
  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    setValidated(false);
  };

  const clearForm = () => {
    setLeadNo("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setComment("");
    setState("");
    setReferrerSource("");
    setReferrerName("");
  };

  const goBackToFirstPage = () => setShowConsenSection(false);

  //Below is the function to remove non-printable ASCII chars
  //and special chars from the pass string
  function removeNonAsciiSpecialCharacters(str) {
    //check if pass value is null or blank.
    //return empty string if pass value is null or blank
    if (str === null || str === "") {
      return "";
    } else {
      //convert the pass value to string
      str = str.toString();
      //remove non-printable ASCII chars and replace it with empty string
      str = str.replace(/[^\x20-\x7E]/g, "");
      //replace all special characters with empty string
      str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}0-9\[\]\\\/]/g, "");
      //return updated string to calling function
      return str;
    }
  }

  //first name validation
  const handleFirstName = (evt) => {
    let tempFirstName = evt.currentTarget.value;
    tempFirstName = removeNonAsciiSpecialCharacters(tempFirstName);
    if (tempFirstName) {
      setFirstName(tempFirstName.trim());
    } else {
      setFirstName("");
    }
  };

  //last name validation
  const handleLastName = (evt) => {
    let tempLastName = evt.currentTarget.value;
    tempLastName = removeNonAsciiSpecialCharacters(tempLastName);
    if (tempLastName) {
      if (tempLastName.includes(" ") && firstName === "") {
        const name = tempLastName.split(" ");
        let fName = name[0];
        let lName = name.slice(1).join(" ");
        setFirstName(fName.trim());
        setLastName(lName.trim());
      } else {
        setLastName(tempLastName.trim());
      }
    } else {
      setLastName("");
    }
  };

  const handleEmailAddress = (evt) => {
    let tempEmailAddress = evt.currentTarget.value;
    if (tempEmailAddress) {
      setEmail(tempEmailAddress.trim());
    } else {
      setEmail("");
    }
  };

  // Phone number validation
  const handleTelephoneNumber = (evt) => {
    //Save Phone number in local storage for Apply
    var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
    var FirstThreeDigit = tmpVal.substring(0, 3);
    var FirstSixDigit = tmpVal.substring(0, 6);

    /* Below Pattern will check numbers between below ranges
                456, 555, 900,
                100, 101, ..., 199
                211, 311, ..., 911  ***811 removed
                370, 371, ..., 379
                960, 961, ..., 969
                800, 801, ..., 855  ***removed
                866, 877, 888, 800855
                */
    var patt =
      /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
    var patt2 = /^800855$/;
    if (patt.test(FirstThreeDigit)) {
      evt.currentTarget.value = "";
      return;
    } else if (patt2.test(FirstSixDigit)) {
      evt.currentTarget.value = "";
      return;
    }

    var curChar = "";
    var areaCode = "";
    var firstThree = "";
    var lastFour = "";
    var extension = "";

    if (tmpVal !== "") {
      for (var i = 0; i < tmpVal.length; i++) {
        curChar = tmpVal.charAt(i);

        if (i < 3) areaCode += curChar;
        if (i < 6 && i > 2) firstThree += curChar;
        if (i < 10 && i > 5) lastFour += curChar;
        if (i > 9) extension += curChar;
      }
    }

    var retVal = "";

    /* Do not put the parentesis if we only have the first three digits of the phone number.
                Once we are past that then start formatting the phone number. This was the main issue
                why the phone number couldn't be blanked out in it's entirety.*/
    if (areaCode !== "" && firstThree === "") retVal += areaCode;
    else if (areaCode !== "" && firstThree !== "")
      retVal += "(" + areaCode + ")";

    if (firstThree !== "") retVal += " " + firstThree;

    if (lastFour !== "") retVal += "-" + lastFour;

    if (extension !== "") retVal += " x" + extension;
    else setPhone(retVal);
  };

  const handleReferrerSource = (evt) => {
    let tempReferrerSource = evt.currentTarget.value;
    if (tempReferrerSource) {
      setReferrerSource(tempReferrerSource.trim());
    } else {
      setReferrerSource("");
    }

    if (tempReferrerSource !== "Other") {
      setReferrerName("");
    }
  };

  const handleReferrerName = (evt) => {
    let tempReferrerName = evt.currentTarget.value;
    tempReferrerName = removeNonAsciiSpecialCharacters(tempReferrerName);
    if (tempReferrerName) {
      setReferrerName(tempReferrerName);
    } else {
      setReferrerName("");
    }
  };

  const showPhoneSoliticationConsent = () => {
    setShowClickToMoreOpt(false);
  };

  const hidePhoneSoliticationConsent = () => {
    setShowClickToMoreOpt(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setFormLoading(true);
      const signupFormData = document.querySelector("#signupForm");
      const getData = serialize(signupFormData);
      const signupForm = document.getElementById("signupForm");
      if (!showConsenSection) {
        let tempname = getData.firstName;
        let firstName_, lastName_;
        if (tempname.includes(" ")) {
          const name = tempname.split(" ");
          firstName_ = name[0];
          lastName_ = name.slice(1).join(" ");
          lastName_ = lastName_.trim();
        } else {
          firstName_ = tempname;
        }
        firstName_ = firstName_.trim();
        const phoneNumber_ = getData.phone;
        const email_ = getData.email;
        const companyName_ = "";
        const comments_ = getData.comment;
        const state_ = getData.state;
        const referrerSource_ = referrerSource;
        let formUrl = getFormURL();
        const chs_name = getData["chs:name"];
        let referrerName_ = referrerName;

        if (referUrl && (referUrl.refer != undefined && referUrl.refer != "undefined")) {
          referrerName_ = atob(referUrl.refer);
        }

        axios
          .post(process.env.REACT_APP_API_EMAIL, {
            firstName: firstName_,
            lastName: lastName_,
            phone: phoneNumber_,
            email: email_,
            comments: comments_,
            state: state_,
            companyName: companyName_,
            referrerSource: "NATARAJ.AI" + referrerSource_,
            referrerName: referrerName_ ? referrerName_ : "",
            "chs:name": chs_name,
            utmSource: utmSource,
            utmMedium: utmMedium,
            utmCampaign: utmCampaign,
            utmContent: utmContent,
            formUrl: formUrl,
            leadSource: "NATARAJ.AI",
            TCPA_Consent_Given: consentValue,
            pushToOptifinow: true,
            campaignId: 3887,
          })
          .then((res) => {
            setLeadNo(res.data.success.brokerId);
            // show the consent section.
            setShowConsenSection(true);
            setFormLoading(false);
          })
          .catch(function (error) {
            setShowFailModal(true);
            setShowSuccessModal(false);
            signupForm.classList.remove("was-validated");
            signupForm.reset();
            setFormLoading(false);
          });
      } else {
        // We need to update the details of lead again as there might be the case user may
        // select I do not consent in the consent section after lead is generated
        const leadNo_ = getData.leadNo;
        let tempname = getData.firstName;
        let firstName_, lastName_;
        if (tempname.includes(" ")) {
          const name = tempname.split(" ");
          firstName_ = name[0];
          lastName_ = name.slice(1).join(" ");
          lastName_ = lastName_.trim();
        } else {
          firstName_ = tempname;
        }
        firstName_ = firstName_.trim();
        const phoneNumber_ = getData.phone;
        const email_ = getData.email;
        const comments_ = getData.comment;
        const state_ = getData.state;
        const referrerSource_ = referrerSource;
        let referrerName_ = referrerName;

        if (referUrl && (referUrl.refer != undefined && referUrl.refer != "undefined")) {
          referrerName_ = atob(referUrl.refer);
        }
        axios
          .post(process.env.REACT_APP_API_EMAIL, {
            leadId: leadNo_,
            firstName: firstName_,
            lastName: lastName_,
            phone: phoneNumber_,
            email: email_,
            comments: comments_,
            state: state_,
            referrerSource: "NATARAJ.AI" + referrerSource_,
            referrerName: referrerName_ ? referrerName_ : "",
            leadSource: "NATARAJ.AI",
            TCPA_Consent_Given: consentValue,
          })
          .then((res) => {
            setLeadNo(leadNo_);
            setShowContactForm(true);
            setShowConsenSection(false);
            setShowSuccessModal(true);
            setReferUrl({ refer: undefined });
            signupForm.classList.remove("was-validated");
            signupForm.reset();
            clearForm();
            setFormLoading(false);
          })
          .catch(function (error) {
            setShowFailModal(true);
            setShowSuccessModal(false);
            signupForm.classList.remove("was-validated");
            signupForm.reset();
            setFormLoading(false);
          });
      }
    }
    setValidated(true);
  };

  /** get states values on mount */
  useEffect(() => {
    // axios
    // .post(
    //   process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
    //   { domain: "ny.swmc.com", companyCode: "SUNWST000" },
    //   {
    //     headers: { "Content-Type": "application/json" },
    //   }
    // )
    // .then((response) => {
    //   let validStates = response.data;
    //   //console.log(response.data);
    //   // Set the dropdown state list option.
    //   setStatesOptions(
    //     validStates.map((item, index) => (
    //       <option key={index} value={item.code}>
    //         {item.name}
    //       </option>
    //     ))
    //   );
    // });

    // Reset first history state
    empDetails = getParams(window.location.href);
    decodeDetails(empDetails);
  }, []);

  useEffect(() => {
    if (key) {
      axios
        .post(
          process.env.REACT_APP_SUNSOFT_API_GET_SESSION_KEY,
          { key: key },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          //console.log("response: " + JSON.stringify(response.data.parameters));
          let userData = response.data.parameters;
          if (userData.borrowerName) {
            // If the borrower name contains a space, split the first and last name.
            let name = userData.borrowerName.split(" ");
            setFirstName(name[0]);
            setLastName(name.slice(1).join(" "));
          } else {
            // If there is no borrower name, set the first and last name accordingly.
            if (userData.borrowerFirstName) {
              setFirstName(userData.borrowerFirstName);
            }
            if (userData.borrowerLastName) {
              setLastName(userData.borrowerLastName);
            }
          }
          setPhone(userData.borrowerPhone);
          setEmail(userData.emailAddress);

          return response;
        })
        .catch((error) => {
          console.error("Get GET_SESSION_KEY Error: ", error);
        });
    }
  }, []);

  return (
    <>

      <div className="formWrapper pad-form">
        {/* <div className="grad4" />
       <div className="grad5" /> */}
        {/* <img src= {imgCDNURL + "dots.png"} className="dots1"/>
       <img src= {imgCDNURL + "dots.png"} className="dots2"/>
       <img src= {imgCDNURL + "dots.png"} className="dots3"/> */}
        <Container >

          <div className="formBody">
            <Container className="form-container">

              <Row>

                <Col sm={12} xl={7} lg={7} md={12} className="ContactUsFormBox">
                  <div className="formHeader">

                    <div>
                      <div className="pt-4 mb-3 text-center">
                        {/* <h3>Sign Up For All Future <a href="https://usemorgan.com/" target="_blank">MORGAN</a> Webinars</h3> */}
                        <h3>Ignite Your Journey to Success: <br />Contact Us to Learn More about {links.current.brandName}, Inspired by Nataraj
                        </h3>
                      </div>
                    </div>
                  </div>
                  {showContactForm && (
                    <Form
                      id="signupForm"
                      autoComplete="off"
                      method="POST"
                      noValidate
                      onSubmit={handleSubmit}
                      validated={validated}
                    >
                      <Row>
                        <Col sm={12} className="d-none">
                          <Form.Group controlId="leadNo" className="form-group">
                            <Form.Control
                              aria-label="Lead Number"
                              placeholder="Lead Number"
                              type="text"
                              value={leadNo}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          sm={12}
                          lg={6}
                          xl={6}
                          md={6}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <Form.Group
                            controlId="firstName"
                            className="form-group"
                          >
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="user-icon" styles={{ background: "#ffff" }}>
                                <CiUser className="user" />
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Full Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                onBlur={handleFirstName}
                                placeholder="Full Name"
                                required
                                type="text"
                                value={firstName}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Full Name.
                              </Form.Control.Feedback>
                            </InputGroup>

                          </Form.Group>
                        </Col>
                        <Col
                          sm={6}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <Form.Group controlId="email" className="form-group">
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="user-icon" styles={{ background: "#ffff" }}>
                                <AiOutlineMail className="user" />
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Email Address"
                                onChange={handleEmailAddress}
                                pattern="^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,64})$"
                                placeholder="Email Address"
                                required
                                type="text"
                                value={email}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Email Address.
                              </Form.Control.Feedback>
                            </InputGroup>

                          </Form.Group>
                        </Col>
                        <Col
                          sm={6}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <Form.Group controlId="phone" className="form-group">
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="user-icon" styles={{ background: "#ffff" }}>
                                <BsTelephone className="user" />
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Telephone Number"
                                maxLength={21}
                                minLength={14}
                                onChange={handleTelephoneNumber}
                                pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                                placeholder="Telephone Number"
                                required={email === ""}
                                type="text"
                                value={phone}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Telephone Number.
                              </Form.Control.Feedback>
                            </InputGroup>

                          </Form.Group>
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={6}
                          lg={6}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >

                          <Form.Group controlId="comment" className="form-group">
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="user-icon" styles={{ background: "#ffff" }}>
                                <BsChatLeftText className="user" />
                              </InputGroup.Text>
                              <Form.Control
                                rows="1"
                                aria-label="comment"
                                placeholder="Comments / Notes"
                                as="textarea"
                                type="text"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Comments / Notes.
                              </Form.Control.Feedback>
                            </InputGroup>

                          </Form.Group>
                        </Col>

                        {/* <Col
                          md={6}
                          sm={12}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <FormGroup className="form-group">
                            <Form.Label>How Did You Hear About Us?</Form.Label>
                            <Form.Select
                              value={referrerSource}
                              placeholder="Select"
                              onChange={handleReferrerSource}
                            >
                              <option>Select</option>
                              {referrerOptions.map((x, id) => {
                                return (
                                  <option key={id} value={x}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </FormGroup>
                        </Col>
                        {referrerSource === "Other" && (
                          <Col
                            md={6}
                            sm={12}
                            className={showConsenSection ? "d-none" : ""}
                          >
                            <Form.Label></Form.Label>
                            <Form.Control
                              // style={{ backgroundColor: "#fafafa" }}
                              className="mt-2 Referral"

                              type="text"
                              placeholder="How Did You Hear About Us"
                              value={referrerName}
                              disabled={referUrl && (referUrl.refer == undefined || referUrl.refer == "undefined") ? false : true}
                              onChange={handleReferrerName}
                            />
                          </Col>
                        )} */}

                        {/* <Col sm={12} className={showConsenSection ? "d-none" : "d-block" }>
                        <Form.Group className="form-group" >                   
                       
                          <Form.Select
                            value={state}
                            placeholder="Your Property State"
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                          >
                            <option value="">Your Property State</option>
                            {statesOptions}
                          </Form.Select>
                        </Form.Group>
                      </Col>  */}


                        <Col
                          sm={12}
                          className={showConsenSection ? "d-block" : "d-none"}
                        >
                          <div className="d-flex">
                            {showClickToMoreOpt ? (
                              <h6
                                className="consentSmall"
                                onClick={showPhoneSoliticationConsent}
                              >
                                {
                                  "Do you consent to Automated Phone Solicitation?"
                                }{" "}
                                <span
                                  id="showConsentText"
                                  className="consentLink showConsentText"
                                  title="Show Phone Solicitation Consent"
                                >
                                  (click to show more)
                                </span>
                              </h6>
                            ) : (
                              <h6
                                className="consentSmall"
                                onClick={hidePhoneSoliticationConsent}
                              >
                                {
                                  "Do you consent to Automated Phone Solicitation?"
                                }{" "}
                                <span
                                  id="hideConsentText"
                                  className="consentLink hideConsentText"
                                  title="Hide Phone Solicitation Consent"
                                >
                                  (click to show less)
                                </span>
                              </h6>
                            )}
                          </div>

                          {!showClickToMoreOpt ? (
                            <p
                              id="phoneSolicitationTerms"
                              className="consent-font pb-0"
                            >
                              By clicking I Consent below, you expressly consent to Celligence International, LLC making (or allowing to be made on its behalf)
                              a phone call, text message or voicemail transmission to the phone number you entered above for the purpose of soliciting your purchase
                              of consumer goods or services (including using an automated system for the selection or dialing of phone numbers, the playing of a recorded
                              message when a connection is made, or the transmission of a pre-recorded voicemail), even if that phone number is on a national, state, or
                              internal Do Not Call Registry. You are not required to click on I Consent below (directly or indirectly) or agree to receive such phone calls,
                              text messages, or voicemail transmissions as a condition of obtaining any goods or services from Sun West Mortgage Company, Inc. or its affiliates.
                            </p>
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col
                          sm={12}
                          className={showConsenSection ? "d-block" : "d-none"}
                        >
                          <Form.Check
                            aria-label="I Consent"
                            defaultChecked
                            className="checkIConsent"
                            id="iConsent"
                            label="I Consent"
                            name="checkIConsent"
                            onClick={() => setConsentValue(true)}
                            type="radio"
                          />
                          <Form.Check
                            aria-label="I DO NOT Consent"
                            className="checkIConsent"
                            id="iDoNotConsent"
                            label="I DO NOT Consent"
                            name="checkIConsent"
                            onClick={() => setConsentValue(false)}
                            type="radio"
                          />
                        </Col>
                        <Col
                          sm={12}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <p className="consent-font">
                            By clicking Submit you also agree to Celligence International, LLC.'s ("Celligence"),{' '}
                            <a href="https://celligence.com/terms" target="_blank"
                              className="footeranchers"
                            >
                              Terms and Conditions
                            </a>{" "}
                            and{' '}
                            <a
                              href="https://www.celligence.com/privacy" target="_blank"
                              // target="_blank"
                              // rel="noopener noreferrer"
                              className="footeranchers"
                            >
                              Security and Privacy Policy
                            </a>
                            , which you may access by clicking on those terms. By communicating with Celligence International, LLC.'s ("Celligence"), by phone, you consent to phone calls being recorded and monitored.
                          </p>
                        </Col>
                        <Col
                          sm={12}
                          className={showConsenSection ? "d-none" : "d-block"}
                        >
                          <div className="divflex">
                            <div>
                              <Button className="btn-submit" type="submit">
                                {formLoading ? (
                                  <>
                                    <Spinner
                                      animation="border"
                                      aria-hidden="true"
                                      as="span"
                                      role="status"
                                      size="md"
                                    />{" "}
                                    Please wait...
                                  </>
                                ) : (
                                  <>Submit</>
                                )}
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          className={showConsenSection ? "d-block" : "d-none"}
                        >
                          <div className="divflex">
                            <div className="backBtnDiv">
                              <Button
                                className="btn-submit"
                                type="button"
                                onClick={goBackToFirstPage}
                              >
                                <>Back</>
                              </Button>
                            </div>
                            <div className="continueBtnDiv btnAdjustDiv">
                              <Button
                                className="btn-submit"
                                type="submit"
                                variant="success"
                              >
                                {formLoading ? (
                                  <>
                                    <Spinner
                                      animation="border"
                                      aria-hidden="true"
                                      as="span"
                                      role="status"
                                      size="md"
                                    />{" "}
                                    Please wait...
                                  </>
                                ) : (
                                  <>Continue</>
                                )}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Col>
              </Row>
            </Container>
            <Modal
              size="lg"
              centered
              show={showSuccessModal}
              onHide={handleSuccessClose}
              backdrop="static"
            >
              <Modal.Header closeButton />
              <Modal.Body className="text-center">
                <h5>
                  <b>
                    Thank you for sharing your details and expressing interest in AngelAi, our ultimate Ai personal loan assistant.
                  </b>
                </h5>
                <h5 style={{ marginTop: "15px" }}>Experience the magic for yourself at <a href={links.current.brandURL} target="_blank">AngelAi.com</a>.</h5>
                <img src={links.current.brandIconLogo} alt="AngelAi Logo" />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleSuccessClose}>Close</Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size="lg"
              centered
              show={showFailModal}
              onHide={handleFailClose}
              backdrop="static"
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <h4>
                  Due to technical difficulties your request could not be
                  processed. Please contact us at&nbsp;
                  <a href={"mailto:inquiry@swmc.com"} className="form-link">
                    inquiry@swmc.com
                  </a>
                  .
                </h4>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleFailClose}>Close</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </div>
    </>
  );
}
