import React, { lazy, Suspense, useEffect, useState } from "react";
import "../src/App.css";
import Header from "./components/Header";
import Body from "./components/Body/Body";
import Form from "./components/SignUpForm/Contactform";
import axios from "axios";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
import jsonData from "./components/ai-branding.json"; // Import the JSON file
import Natarajdata from "./components/nataraj.json"; // Import the JSON file

const Footer = lazy(() => import("./components/Footer/Footer"));
const NatarajComponent = lazy(() => import("./components/Devata/devata"));
const DivineDance = lazy(() => import("./components/DivineDance/DivineDance"));
const Empowerment = lazy(() => import("./components/Empowerment/Empowerment"));
const AngelAi = lazy(() => import("./components/AngelAi/angelai"));
const DualityDance = lazy(() => import("./components/DualityDance/dualityDance"));

function App() {
  const [webinarData, setWebinarData] = useState();
  const [bannerData, setBannerData] = useState();
  const [DevataData, setDevataData] = useState();
  const [DivineDanceData, setDivineDanceData] = useState();
  const [EmpowermentData, setEmpowermentData] = useState();
  const [AngelAiData, setAngelAiData] = useState();
  const [timerData, setTimerData] = useState();
  const [formData, setFormData] = useState();

  const appVersion = appversion.version;

  const [links, setLinks] = useState(null);
  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_ANGELAI_JSON);
        setLinks(response.data);
      } catch (error) {
        console.error("Error fetching links:", error);
        setLinks(jsonData); // Use local JSON data if fetching fails
      }
    };

    if (!links) {
      fetchLinks(); // Fetch JSON only if the links data is not available
    }
  }, [links]);

  useEffect(() => {
    if (!links) {
      setLinks(jsonData); // Set local JSON data as fallback initially
    }
  }, []);

  useEffect(() => {
    const fetchNatarajJSON = async () => {
      try {
        const url = process.env.REACT_APP_NATARAJAIJSON_CDN_URL; // Use the CDN URL from environment variables
        const response = await axios.get(url);
        const natarajJsonData = response.data;
        setWebinarData(natarajJsonData);
        setBannerData(natarajJsonData.banner);
        setDevataData(natarajJsonData.Devata);
        setDivineDanceData(natarajJsonData.DivineDance);
        setEmpowermentData(natarajJsonData.Empowerment);
        setAngelAiData(natarajJsonData.AngelAi);
      } catch (error) {
        console.log(error);
        // If there's an error fetching the JSON from the CDN, use the local JSON data instead
        setWebinarData(Natarajdata);
        setBannerData(Natarajdata.banner);
        setDevataData(Natarajdata.Devata);
        setDivineDanceData(Natarajdata.DivineDance);
        setEmpowermentData(Natarajdata.Empowerment);
        setAngelAiData(Natarajdata.AngelAi);
      }
    };

    fetchNatarajJSON();
  }, []);

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} // If not passed, nothing appears at the time of new version check.
      // If public assets are hosted somewhere other than root on your server.
      metaFileDirectory={"process.env.PUBLIC_URL"}
    >
      <div className="bodyContainer">
        <Header links={links} fetchpriority="high" />
        {bannerData &&
          <>
            <Body links={links} bannerData={bannerData} timerData={timerData} fetchpriority="high" />
            <Suspense fallback={<div />}>
              <NatarajComponent DevataData={DevataData} />
            </Suspense>
            <Suspense fallback={<div />}>
              <DivineDance DivineDanceData={DivineDanceData} /> {/* Use DivineDanceData prop */}
            </Suspense>
            <Suspense fallback={<div />}>
              <AngelAi AngelAiData={AngelAiData} /> {/* Use AngelAiData prop */}
            </Suspense>
            <Suspense fallback={<div />}>
              <Empowerment EmpowermentData={EmpowermentData} /> {/* Use EmpowermentData prop */}
            </Suspense>
            <Suspense fallback={<div />}>
              <DualityDance EmpowermentData={EmpowermentData} /> 
            </Suspense>
            <Form links={links} formData={formData} />
            <Suspense fallback={<div />}>
              <Footer links={links} />
            </Suspense>
          </>
        }
      </div>
    </CacheBuster>
  );
}

export default App;
