import React, { useState } from "react";
import { Navbar, Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import "./userwayWidget.css";

export default function Header({ links }) {
  const [navbar, setNavbar] = useState(false);
  const imgURL = process.env.REACT_APP_CDN_LINK;

  const changebackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changebackground);
  return (
    <Navbar expand="lg"
      fixed="top"
      bg="dark"
      variant="dark"
      className={navbar ? 'navbar active' : 'navbar'}>
      <Container>
        <div>
          <a href={links.current.brandURL} target="_blank">
            <Image src={"https://stagingresources.swmc.com/angel-ai/angelai-brandlogo.webp"}
              alt="AngelAi Logo" title="AngelAi Logo" className="sunwest-logo" />
          </a>
        </div>
      </Container>
    </Navbar>
  );
}
